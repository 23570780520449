#app > div > nav > div.v-navigation-drawer__content > div:nth-child(2) > a:nth-child(1) {
    display: none;
}

input[type="text"] {
    height: 3rem !important;
}

.btn-primary {
    min-width: 8rem;
    box-shadow: none !important;
}

.submit-btn {
    box-shadow: none !important;
}

.btn-success {
    box-shadow: none !important;
    justify-content: center;
    display: flex !important;
}

.prev-btn {
    min-width: 8rem;
    box-shadow: none !important;
    border: 0.5px solid #ddd !important;
}

#simpliform .h4, #simpliform h4 {
    font-size: 24px !important;
}

lime-form {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }


